////////
// Accordian
// ---------------
// @desc 		Accordian object that envelopes all .accordian DOm objects
// @param		DOM element
////////
var Accordian = function(element) {

	this.selectors = {
		section: ".accordian-section, .simple-accordian-section",
		title: ".accordian-title, .simple-accordian-title",
		content: ".accordian-content, .simple-accordian-content",
		control: ".accordian-control, .simple-accordian-control"
	};
	this.flags = {
		ACTIVE: "active",
		CLOSED: "closed"
	};

	// Getters
	this.element = $(element);
	this.sections = this.element.find(this.selectors.section);
	this.content = this.element.find(this.selectors.content);
	this.control = this.element.find(this.selectors.control);

	// Events
	this.title = this.element.find(this.selectors.title);
	this.title.on("click", this, this.toggleSection);
	this.control.on("click", this, this.toggleSection);

	// Init
	this.state = this.flags.CLOSED;
	this.content.hide();
}

Accordian.prototype.toggleSection = function(e) {

	var obj = e.data;
	var target = $(e.target);
	var inner = target.parent();
	var section = inner.parent();
	var content = section.find(obj.selectors.content);

	console.log(section, section.hasClass(obj.flags.ACTIVE));

	if(obj.content) {
		if(section.hasClass(obj.flags.ACTIVE) == false) {
			section.addClass(obj.flags.ACTIVE);
			gsap.fromTo(content, { height:0, display:"flex" }, { height:"auto", display:"flex", duration: 1 });
		} else {
			section.removeClass(obj.flags.ACTIVE);
			gsap.fromTo(content, {  height:"auto", display:"flex" }, { display:"none", height:0, duration: 1 });
		}
	}
}
