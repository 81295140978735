/*******
** Filters
*******/
App.Filters = (function() {

	var selectors = {
		filters: ".block .filter"
	};

	/*
	Init
	*/
	function init() {
		try {
			var items = $(selectors.filters);
			items.each(function(i, item) {
				new Filter(item);
			});
		} catch(e) {
			App.log("Issue initiating Filters", e);
		}
	}

	App.register(function() {
		App.log("Registered: App.Filters");
		init();
	});

})();
