//////////////////
// Cookie Notice Controller
//////////////////
App.CookieNotice = (function() {
  var selectors = {
    notice: ".cookie-notice",
    closeButton: ".cookie-notice-accept"
  };

  var flags = {
    COOKIE_NAME: "NOTICE_ACCEPT",
    COOKIE_STATUS: "agreed"
  };

  var notice, closeButton;

  function init() {
    try {
      notice = $(selectors.notice);
      closeButton = notice.find(selectors.closeButton);
      render();
    } catch (e) {
      console.log("Error locating " + selectors.notice, e);
    }
  }

  function createCookie(cookieName, cookieValue, daysToExpire) {
    var date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    document.cookie =
      cookieName + "=" + cookieValue + "; expires=" + date.toGMTString();
  }

  function accessCookie(cookieName) {
    var name = cookieName + "=";
    var allCookieArray = document.cookie.split(";");
    for (var i = 0; i < allCookieArray.length; i++) {
      var temp = allCookieArray[i].trim();
      if (temp.indexOf(name) == 0) {
        return temp.substring(name.length, temp.length);
      }
    }
    return "";
  }

  function render() {
    // Show cookies notice if cookie not set
    var cookie = accessCookie(flags.COOKIE_NAME);
    if (accessCookie(flags.COOKIE_NAME) != flags.COOKIE_STATUS) {
      notice.css("display", "block");
      gsap.to(notice, { bottom: 0, duration: 2, delay: 1 });

      // Enable button and track cookie set
      closeButton.on("click", function() {
        createCookie(flags.COOKIE_NAME, flags.COOKIE_STATUS, 365 * 2);
        gsap.to(notice, {
          bottom: -notice.outerHeight(),
          display: "none",
          duration: 0.5
        });
        return false;
      });
    } else {
      // If not needed, strip from the DOM
      notice.remove();
    }
  }

  function initTermlyCustomisation() {
    // Fixing Termly's US-centric, uneditable spelling and restricted policy functionality

    // Listen for custom load event
    window.addEventListener("onWidgetLoadComplete", function(e) {
      // Find banner
      var banner = $("#termly-code-snippet-support");

      // Intro text
      var banner_intro = banner.find(
        "div > div#consent > div > p > span > span:first-of-type"
      );

      // Policy click
      var banner_policy_link = banner.find(
        "div > div#consent > div > p > span > span:nth-of-type(2)"
      );

      // Preference buttons
      var pref_buttons = $(
        "#termly-code-snippet-support .t-preference-button button"
      );

      function policyLinkOverride(e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        // Override path
        window.location.href = "/cookie-policy";
      }

      function modalWindowOverride(modal) {
        // Modify description
        var inner_desc = modal.find(
          "p[class*='termly-consent-banner-description-']"
        );
        inner_desc.html(inner_desc.html().replace("optimize", "optimise"));

        // Modify policy link
        var inner_policy_link = modal.find(
          "span[class*='termly-consent-banner-policy-link-']"
        );
        inner_policy_link[0].addEventListener(
          "click",
          policyLinkOverride,
          true
        );
      }

      // Override policy link
      if (banner_policy_link.length) {
        banner_policy_link[0].addEventListener(
          "click",
          policyLinkOverride,
          true
        );
      }

      // Watch for preferences modal
      var modal = banner.find("div.t-preference-modal");
      if (!modal.length) {
        var observer = new MutationObserver(function(mutations) {
          modal = banner.find("div.t-preference-modal");
          if (modal.length) {
            observer.disconnect();
            modalWindowOverride(modal);
          }
        });
        observer.observe(banner[0], { childList: true, subtree: true });
      }
    });
  }

  App.register(function() {
    App.log("Registered: App.CookieNotice");
    initTermlyCustomisation();
  });
})();
