//////////////////
// Overlay Controller
//////////////////
App.Overlay = (function() {

	var activeOverlay = null;

	/*
	Manage overlays
	*/
	function manageOverlays(newOverlay) {
		if(activeOverlay !== null) {
			if(activeOverlay.isActive() == true) {
				// activeOverlay.close();
			}
			activeOverlay = null;
		}
		activeOverlay = newOverlay;
	}

	/*
	Open person overlay
	*/
	function openPersonOverlay(personObj, personUrl, personSlug, searchQuery) {
		activeOverlay = new PersonOverlay(personObj, personUrl, personSlug, searchQuery);
		manageOverlays(activeOverlay);
	}

	App.register(function() {
		App.log("Registered: App.Overlay");
		App.Overlay.addPersonOverlayLinks();
	});

	return {

		addPersonOverlayLinks : function(parent) {
			try {

				// Set a flexible root
				var root = parent || $(".page-content");

				// Find the links
				var links = root.find("a.person-thumb, a.type-person, .authors a[href*='team']");

				// Add mouse events
				links.on("click", function(e) {

					// Get the element
					var el = $(this);

					// Extract the URL name
					if (el[0].tagName == "ARTICLE") {
						var href = el.find("a[href]").attr("href");
					} else if(el[0].tagName == "A") {
						var href = el.attr("href");
					}

					if(href) {
						var parts = href.split("/");

						// Open the overlay but only once!
						if(!el.hasClass("loading")) {
							openPersonOverlay(
								el,
								href,
								parts.pop(),
								document.location.search || false
							);
						}
					}
					return(false);
				});
			} catch(e) {
				console.log("Error adding Person Overlay links ", e);
			}
		}
	}

})();
