//////////////////
// Utilities
//////////////////
App.Utilities = (function() {

	App.register(function() {
		App.log("Registered: App.Utilities");
	});

	return {
		/*
		* Checks for small screen size based on CSS attribute
		*/
		isSmallScreen: function() {
			var bkSniff = $(".site-nav-touch-controls");
			return(bkSniff.css("display") !== "none");
		},
		/*
		* Gets saved scrollposition from the window.name object
		*/
		getSavedScrollPosition: function() {
			var name = window.name;
			if(name.length > 0 && name.indexOf("y") > -1) {
				var pos = Number(name.split("y")[1]);
				return(pos)
			}
			return(false);
		},
		/*
		* Saves the scrollposition to the window.name object
		*/
		saveCurrentScrollPosition : function () {
			window.name = "y" + window.scrollY;
		}
	}

})();
