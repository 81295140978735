/*******
** Investments
*******/
App.Investments = (function() {

	/*
	Init
	*/
	function init() {
    var investmentsDOM = document.querySelector('.block-investments-grid .content .investments');
		try {
      var infScroll = new InfiniteScroll( investmentsDOM, {
        path: '.load-more',
        append: '.investment-thumb',
        history: false,
        hideNav: '.pagination',
      });

      // Sniff any duplicates and remove
      infScroll.on( 'append', function( body, path, items, response ) {
        for(var i = 0; i < items.length; i++) {
          var els = investmentsDOM.querySelectorAll("#" + items[i].id);
          if(els.length > 1) {
            // Duplicate detected
            App.log("Removing duplicate investment: ", items[i].id);
            els[0].remove();
          }
        }
      });
		} catch(e) {
			App.log("Issue initiating Investments", e);
		}
	}

	App.register(function() {
		App.log("Registered: App.Investments");
	});

  return {
    init: init
  }

})();
