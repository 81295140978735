////////
// Corner Image
// --------
// @desc		Corner image element that sticks to the edge of the browser
// --------
// @param		element		The DOM element to wrap with Corner Image
////////
var CornerImage = function(element) {

  this.element = $(element);
  this.parent = this.element.parent();
  this.resizeTimer;

  $(window).on("resize", this, function(e) {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(function(obj) {

      console.log("Window resizing", this);

    }, 250, e.data);
  });

}

CornerImage.prototype.resizeImage = function() {
  var offset = this.element.offset();
  console.log(offset);
}
