/*******
 ** Homepage Carousel
 *******/
App.HomeCarousel = (function() {
  var slideDuration = 7000;
  var fadeAwayDuration = 1300;

  var index,
    slide,
    timer,
    flkty,
    header,
    nav,
    previousCell,
    displayCount = 0;

  function initFlickity() {
    // Setup flickity
    flkty = new Flickity(".home-carousel .carousel", {
      cellAlign: "left",
      contain: true,
      draggable: App.Utilities.isSmallScreen(),
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: slideDuration,
      pauseAutoPlayOnHover: false,
      setGallerySize: false,
      lazyLoad: true,
      pageDots: true,
      selectedAttraction: 0.01,
      friction: 0.2
    });

    // Get header
    header = $(".site-header");
    nav = $(".nav-header");

    // Capture events
    // flkty.on("settle", showSlideContent);
    flkty.on("change", setMenuContrast);
    flkty.on("change", readySlideContent);
    showSlideContent();
    setMenuContrast();
  }

  function readySlideContent() {
    var a = setTimeout(showSlideContent, slideDuration / 6);
  }

  function setMenuContrast() {
    var cell = flkty.selectedCell;
    var el = $(cell.element);
    var contrast = String(el.attr("data-contrast"));
    var clsPrefix = "o-style-";

    if (contrast.length) {
      var diffContrast = contrast == "dark" ? "light" : "dark";
      var newCls = clsPrefix + contrast;
      var existingCls = clsPrefix + diffContrast;

      if (!header.hasClass(newCls)) {
        header.removeClass(existingCls);
        header.addClass(newCls);
      }
    }
  }

  function stopAutoPlayCheck() {
    displayCount++;
    if (displayCount == flkty.slides.length + 1) {
      flkty.stopPlayer();
    }
  }

  function hideSlideContent() {
    // if (flkty.selectedCell) {
    //   var cell = flkty.selectedCell;
    // }

    var cell = previousCell;

    if (cell) {
      // Get content
      var text = $(cell.element).find(".home-carousel-inner");
      if (App.Utilities.isSmallScreen() == false) {
        // Only do anything on desktop
        console.log("HIDE:", text);
        console.log(flkty.slides);
        text.addClass("hide-text");
        text.removeClass("show-text");
        // gsap.to(text[0], { opacity: 0, x: "-=100", duration: 1.3 });
      }
    }
  }

  function showSlideContent() {
    if (flkty && flkty.selectedCell) {
      var cell = flkty.selectedCell;
    }

    // Is this the first cell?
    if (!cell && !previousCell) {
      cell = flkty.cells[0];
    }

    if (cell) {
      // Check if this
      stopAutoPlayCheck();

      // Get content
      var text = $(cell.element).find(".home-carousel-inner");

      // Timeout to hide it again
      timer = hideSlideContent(hideSlideContent, 5000);

      if (App.Utilities.isSmallScreen()) {
        // Show content immediately on mobile
        text.css("opacity", 1);

        // Force height
        header.height($(cell.element).height() + nav.height());
      } else {
        // Animate on desktop
        console.log("SHOW:", text);
        text.addClass("show-text");
        text.removeClass("hide-text");

        // gsap.fromTo(
        //   text[0],
        //   { opacity: 0, x: "+=150" },
        //   { opacity: 1, x: 0, duration: 1.3, ease: "power2.out" }
        // );
      }

      // Hide the previous cell, if applicable
      if (previousCell && App.Utilities.isSmallScreen() == false) {
        var previousText = $(previousCell.element).find(".home-carousel-inner");
        previousText.css("opacity", 0);
      }

      // Store previous cell
      previousCell = cell;
    }
  }

  function fadeTextAway(slide) {
    var el = slide.cells[0];
    var text = $(el.element).find(".home-carousel-inner");
    gsap.fromTo(text[0], { opacity: 0, x: "-= 100", duration: 1 });
  }

  App.register(function() {
    App.log("Registered: App.Carousel.Home");
  });

  return {
    init: initFlickity
  };
})();
