//////////////////
// Carousel Controller
// ------------
// Controller for .carousel objects
//////////////////
App.Carousel = (function() {

	var carouselsDOM, carouselsCollection = [];
	var selectors = {
		carousels: ".carousel",
		simpleCarousels: ".carousel-simple"
	};

	/*
	Render all carousels in the page
	*/
	function renderCarousels() {
		try {
			carousels = $(selectors.carousels);
			if(carousels.length) {
				$.each(carousels, function(i, element) {
					carouselsCollection.push(
						new Carousel(element)
					);
				});
			}
		} catch(e) {
			console.log("Error locating " + selectors.carousels, e);
		}
	}


	function renderSimpleCarousels() {
		try {
			var s = $(selectors.simpleCarousels);
			if(s.length) {
				$.each(s, function(i, element) {
					var el = $(element);

					el.find(".control-next").on("click", function(e) {
						console.log(e);
						var active = el.find(".insight-thumb");
						var next = active.next();
						active.removeClass("active");
						next.addClass("active");
					});

					el.find(".control-prev").on("click", function(e) {

					})
				});
			}
		} catch(e) {
			console.log("Error locating " + selectors.carousels, e);
		}
	}

	App.register(function() {
		App.log("Registered: App.Carousel");
		renderCarousels();
		renderSimpleCarousels();
	});

})();
