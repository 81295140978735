//////////////////
// Navigation Controller
// ------------
// Navigation control layer for .site-nav to enhance basic CSS behaviours
//////////////////
App.Navigation = (function() {

	// varants
	var options = {
		HOVER: "nav-hover",
		SUB_HOVER: "sub-nav-hover",
		SUB_OPEN: "sub-nav-open",
		MOBILE_NAV_OPEN: "mobile-nav-open",
		ACTIVE: "active"
	};

	var selectors = {
		siteHeader: ".site-header",
		navWrapper: ".site-nav",
		subNavWrapper: ".nav-sub",
		nav: ".site-nav .site-nav-items",
		navItem: ".site-nav .nav-item",
		mobileOpenButton: ".nav-touch-open",
		mobileCloseButton: ".nav-touch-close",
		mobileSubNavToggles: ".nav-sub-toggle"
	};

	// Variables
	var navWrapper,
			nav,
			navItems,
			navHoverTimer = false,
			mobileOpenButton,
			mobileCloseButton,
			mobileSubNavToggles;

	// Cache
	var activeLink = false,
			activeSubNav = false;

	function getGlobalVariables() {
		try {
			siteHeader = $(selectors.siteHeader);
			navWrapper = siteHeader.find(selectors.navWrapper);
			nav = siteHeader.find(selectors.nav);
			navItems = nav.find(selectors.navItem);

		} catch(e) {
			console.log("Couldn't get global navigation items", e);
		}
	}

	/*
	Desktop
	*/
	function addDesktopNavigationHandlers() {
		try {
			// navItems.on("mouseover", hoverDesktopMenu);
		} catch(e) {
			console.log("Couldn't add desktop navigation controls", e);
		}
	}

	function hoverDesktopMenu(e) {
		if(App.Utilities.isSmallScreen() == false) {

			// activeLink = e.target;
			siteHeader.addClass(options.HOVER);
			nav.addClass(options.HOVER);

			// Check for subnav
			var link = $(e.target);
			var item = link.parents(selectors.navItem);
			var sub = item.children(".nav-sub");
			if(sub.length && sub.children("a").length && activeSubNav == false) {

				console.log("in");

				siteHeader.addClass(options.SUB_HOVER);

				// Save sub nav object
				activeSubNav = sub;

				// Animate in
				gsap.fromTo(
					activeSubNav,
					{ display:"none", opacity:0 },
					{ display: "block", opacity:1, duration:.3 }
				);
			}
		}
	}

	function leaveDesktopMenu(e) {
		if(App.Utilities.isSmallScreen() == false) {

			console.log("out", e.target);

			siteHeader.removeClass([options.HOVER, options.SUB_HOVER].join(" "));
			nav.removeClass(options.HOVER);
			if(activeSubNav) {
				gsap.to(activeSubNav, { display:"none", opacity:0, duration:.3 } );
				activeSubNav = false;
			}
		}
	}


	/*
	Mobile
	*/
	function addMobileNavigationHandlers() {
		try {

			mobileOpenButton = siteHeader.find(selectors.mobileOpenButton);
			mobileCloseButton = siteHeader.find(selectors.mobileCloseButton);
			mobileSubNavToggles = siteHeader.find(selectors.mobileSubNavToggles);

			mobileOpenButton.on("click", openMobileMenu);
			mobileCloseButton.on("click", closeMobileMenu);
			mobileSubNavToggles.on("click", toggleMobileSubMenu);

		} catch(e) {
			console.log("Couldn't add small screen navigation", e);
		}
	}

	function openMobileMenu(e) {
		if(App.Utilities.isSmallScreen()) {
			siteHeader.addClass(options.MOBILE_NAV_OPEN);
		}
		// nav.addClass(options.ACTIVE);
	}

	function closeMobileMenu(e) {
		if(App.Utilities.isSmallScreen()) {
			siteHeader.removeClass(options.MOBILE_NAV_OPEN);
		}
		// nav.removeClass(options.ACTIVE);
	}

	function toggleMobileSubMenu(e) {
		if(App.Utilities.isSmallScreen()) {
			var btn = $(e.target);
			btn.toggleClass(options.SUB_OPEN);
		}
	}

	App.register(function() {
		App.log("Registered: App.Navigation");
		//
		getGlobalVariables();
		// addDesktopNavigationHandlers();
		addMobileNavigationHandlers();
	});

})();
