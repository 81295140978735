////////
// Slider
// --------
// @desc		Manages all internal behaviours to do with a slider
// --------
// @param		element		The DOM element to wrap with Slider
////////
var Slider = function(element,duration) {

	this.selectors = {
		paneWrapper: 	".slider",
		pane: 				".slider-pane",
		controlNext: 	".control-next",
		controlPrev: 	".control-prev"
	};

	this.flags = {
		ACTIVE: "active"
	};

	this.duration = 5000;
	if(duration) {
		this.duration = duration;
	}
	this.element = $(element);
	this.panes = this.element.find(this.selectors.pane);
	this.numOfPanesVisible = 1;

	var numVisible = this.element.data("panes-visible");
	if(numVisible) {
		this.numOfPanesVisible = numVisible;
		console.log(this.numOfPanesVisible);
	}

	// Only initiate if there are multiple panes
	if(this.panes.length > 1) {
		this.renderPaginationControl();
		this.displayPane(0);
    // this.autoCarousel();
	}
}

Slider.prototype.autoCarousel = function() {
  var obj = this;
  var c = setInterval(function() {
    obj.displayNextPane()
  }, this.duration);
}

// Displays the next pane
Slider.prototype.displayNextPane = function(e) {
	var obj = e.data;
  try {

    // Find the index of the active pane
    var index, flag = obj.flags.ACTIVE;
    $.each(obj.panes, function(i, pane) {
      if($(pane).hasClass(flag)) {
        index = i;
      };
    });

    // Increment index
    index += 1;

    // Reset to start
    if(index == obj.panes.length) {
      index = 0;
    }

    // Display pane
    obj.displayPane(index);

  } catch(e) {
    console.log("Next pane not found ", e);
  }
}

// Displays the previous pane
Slider.prototype.displayPreviousPane = function(e) {
	var obj = e.data;
  try {

    // Find the index of the active pane
    var index, flag = obj.flags.ACTIVE;
    $.each(obj.panes,function(i, pane) {
      if($(pane).hasClass(flag)) {
        index = i;
      };
    });

    // Increment index
    index -= 1;
    // Reset to start
    if(index < 0) {
      index = obj.panes.length - 1;
    }

		// Display pane
    obj.displayPane(index);

  } catch(e) {
    console.log("Next pane not found ", e);
  }

}

// Display a specific pane based on Array index in this.pane collection
Slider.prototype.displayPane = function(paneIndex) {
  var flag = this.flags.ACTIVE;
  var active = this.panes[paneIndex];

	$.each(this.panes, function (i, pane) {
    $(pane).removeClass(flag);
	});

  $(active).addClass(this.flags.ACTIVE);
}


// Render the pagination control into the DOM
Slider.prototype.renderPaginationControl = function() {
	var previous = '<span class="' + this.selectors.controlPrev.split(".")[1] + '" aria-title="Previous"></span>';
	var next = '<span class="' + this.selectors.controlNext.split(".")[1] + '" aria-title="Next"></span>';

	var obj = this; // scope helper
	$(this.panes[this.panes.length - 1]).after(
		previous
		+ next
	);

	// Get the DOM objects
	this.previous = $(this.element).find(this.selectors.controlPrev);
	this.next = $(this.element).find(this.selectors.controlNext);

	this.previous.on("click", this, this.displayPreviousPane);
	this.next.on("click", this, this.displayNextPane)

}
