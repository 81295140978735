/*******
** Animations
*******/
App.Animations = (function() {

	var selectors = {
		blocks: ".block:not(.block-investment-introduction), .site-header:not(.header-type-home) .page-header"
	};

  var flags = {
    ENTER: "block-enter",
    SEEN: "block-seen",
		JS: "js"
  };

  function blockAnimationEnter(el) {
		var item = $(el);
		if(!item.hasClass(flags.ENTER)) {
			item.addClass(flags.ENTER);

			if(item.hasClass("page-header")) {
				var headerElements = item.find("h1, .strapline, .summary, .graphic");
				gsap.fromTo(headerElements, { opacity:0, y:"+=20" }, { opacity:1 , y:0, duration: 1.5, stagger: .5, onComplete:function() {
					headerElements.css("transform", "none");
				}});

			} else {
				var contentElements = item.children("*");
				gsap.fromTo(contentElements, { opacity:0, y:"+=20" }, { opacity:1 , y:0, duration: 1.5, onComplete:function() {
					item.css("transform", "none");
				}});
			}
		}
  }
  function blockAnimationExit(el) {
    $(el).addClass(flags.SEEN);
  }

	function animateBlocks() {
    if(inView) {
  		inView.offset(100);
  		inView(selectors.blocks).on("enter", blockAnimationEnter);
  		inView(selectors.blocks).check();
    }
	}

	function addJSFlag() {
		$("html").addClass(flags.JS);
	}

	function animateHeader() {
		var header = $(".site-header-top.nav-header");
		gsap.fromTo(header, { opacity:0 }, { opacity:1, duration: 1.5, onComplete:function() {
			header.css("transform", "none");
		}});
	}

	App.register(function() {
		App.log("Registered: App.Animations");
		addJSFlag();
    animateBlocks();
		animateHeader();

		// Homepage modifications
		if($("#site.page-home").length) {
			blockAnimationEnter($(".block-in-numbers")[0]);
		}
	});

	return {
		GSAP: {
			EASE: "power1.inOut"
		}
	}

})();
