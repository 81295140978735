/*******
** Accordians
** ------------
** Managing all Accordians within the application
*******/
App.Accordians = (function() {

	// varants
	var selectors = {
		accordian: ".block-accordian, .simple-accordian"
	};

	// Variables
	var accordianCollection = [];

	/*
	Init
	*/
	function initAccordians() {
		try {
			var items = $(selectors.accordian);
			items.each(function(i, item) {
				accordianCollection.push(
					new Accordian(item)
				);
			});
		} catch(e) {
			App.log("Issue initiating Accordians", e);
		}
	}

	App.register(function() {
		App.log("Registered: App.Accordians");
		initAccordians();
	});

})();
