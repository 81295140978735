////////
// PersonOverlay
// ---------------
// @desc 		Overlay object that envelopes all .person.overlay DOM objects
// @param		DOM element
////////
var PersonOverlay = function(personObj, personUrl, personSlug, searchQuery) {

	App.log("PersonOverlay: [Init]", personUrl);

	this.selectors = {
		site: "#site",
    overlay: ".overlay.overlay-person",
		outer: ".overlay-inner",
    inner: ".overlay-inner > .overlay-person-content",
		close: ".overlay-close"
	};

	this.flags = {
		ACTIVE: "active",
		LOADING: "loading",
    NO_SCROLL: "no-scroll"
	};

	this.targetPersonObj = personObj;
	this.sourceUrl = personUrl;
  this.personSlug = personSlug;
	this.searchQuery = searchQuery;

	// Start XHR content load
	this.load();
}


/***
* Initiate overlay
***/
PersonOverlay.prototype.init = function() {
	this.element = $(this.selectors.overlay);
	this.outer = this.element.find(this.selectors.outer);
	this.innerDOM = this.element.find(this.selectors.inner)[0];
	this.closeButton = this.element.find(this.selectors.close);

	// Close button
	this.closeButton.on("click", this, this.close);

	// Sniff outside mouse clicks
	$(window).on("click", this, function(e) {
		if (e.target.isEqualNode(e.data.element[0]) == true) {
			e.data.closeButton.trigger("click");
			$(window).off("click");
		}
	});
}


/***
* Check if that person (overlay) is being shown
***/
PersonOverlay.prototype.isActive = function() {
	if(!this.element) {
		return(false);
	} else {
	  return(
	    this.element.hasClass(this.flags.ACTIVE)
	  );
	}
}


/***
* Close the overlay
***/
PersonOverlay.prototype.close = function(e) {
	var obj = e ? e.data : this;

	gsap.to(obj.closeButton, { opacity:0, duration: .8});
	gsap.fromTo([obj.element[0], obj.outer[0]], { opacity:1 }, { opacity:0, delay:.2, duration: .5, stagger:.3, onComplete: function() {
		obj.element.removeClass(obj.flags.ACTIVE);
		obj.element.remove();
		$("body").removeClass(obj.flags.NO_SCROLL);
	} });

	return(false);
}


/***
* Open the overlay
***/
PersonOverlay.prototype.open = function(e) {
	var obj = e ? e.data : this;

  $("body").addClass(this.flags.NO_SCROLL);
	obj.targetPersonObj.removeClass(this.flags.LOADING);
	this.element.addClass(this.flags.ACTIVE);
	obj.closeButton.hide();

	gsap.fromTo(obj.element, { opacity:0 }, { opacity:1, duration: 1 });
	gsap.fromTo(obj.outer, { x:"100%" }, { x:0, duration: 1, delay:.5 });
	gsap.fromTo(obj.closeButton, { opacity:0, display:"none" }, { opacity:1, display:"block", duration: .6, delay:2 });

	return(false);
}


/***
* Load the person data from actual page (team/person-name)
***/
PersonOverlay.prototype.load = function(initFunc) {

	// Define target
	var obj = this;

	// Check not a duplicate call
	if(!obj.targetPersonObj || obj.targetPersonObj.hasClass(this.flags.LOADING)) {
		App.log("Invalid call", targetPersonObj);
		return(false);
	}

	// Setup loader
	var url = this.sourceUrl;
	if(this.searchQuery !== false) {
		url += this.searchQuery;
	}
	var loader = new DOMLoader(url, this.selectors.overlay);

	// Add loader over page
	obj.targetPersonObj.addClass(this.flags.LOADING);

	// Start XHR load
	loader.load(

		// On Success
		function(domPartial) {

			// Wrap new content
			var personOverlay = $(domPartial);

			// If DOM content available
			if(personOverlay.length) {

				App.log("PersonOverlay: [Rendering] ", personOverlay);

				// Get existing overlay inner
				var existingOverlay = $(obj.selectors.overlay);
				if(existingOverlay.length) {
					existingOverlay.remove();
				}

				// Append new overlay to #site
				$(obj.selectors.site).append(personOverlay);

				// Initiate any mouse events
				App.Overlay.addPersonOverlayLinks(personOverlay);

				// Init overlay
				obj.init();
				obj.open();
			}
		},

		// On Error
		function(data) {
			App.log(data); // NOTE: Regular 200 messages!
		}
	);
}
