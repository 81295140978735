////////
// Carousel
// --------
// @desc		Manages all internal behaviours to do with a carousel
// --------
// @param		element		The DOM element to wrap with Carousel
// @param		duration	Lenght of time in milliseconds that a oane should display for
////////
var Carousel = function(element, duration) {

	this.selectors = {
		paneWrapper: 	".carousel-wrapper",
		pane: 				".carousel-pane",
		pagination: 	".carousel-pagination",
		bar: 					".carousel-bar"
	};

	this.flags = {
		ACTIVE: "active"
	};

	this.duration = 5000;
	if(duration) {
		this.duration = duration;
	}
	this.element = element;
	this.paneWrapper = $(this.element).one(this.selectors.paneWrapper);
	this.panes = $(this.element).find(this.selectors.pane);
	this.pagination = null;

  this.updatePagination = function(index, pane) {
  	var i = index + 1;
  	this.pagination.find(this.selectors.bar).removeClass(this.flags.ACTIVE);
  	this.pagination.find(this.selectors.bar + ":nth-of-type(" + i + ")").addClass(this.flags.ACTIVE);
  }

	// Only initiate if there are multiple panes
	if(this.panes.length > 1) {
		this.renderPaginationControl();
		this.displayPane(0);
    this.autoCarousel();
	}
}

Carousel.prototype.autoCarousel = function() {
  var obj = this;
  var c = setInterval(function() {
    obj.displayNextPane()
  }, this.duration);
}

// Displays the next pane
Carousel.prototype.displayNextPane = function() {
  try {

    // Find the index of the active pane
    var index, flag = this.flags.ACTIVE;
    $.each(this.panes,function(i, pane) {
      if($(pane).hasClass(flag)) {
        index = i;
      };
    });

    // Increment index
    index += 1;

    // Reset to start
    if(index == this.panes.length) {
      index = 0;
    }

    // Display pane
    this.displayPane(index);

  } catch(e) {
    console.log("Next pane not found ", e);
  }

}

// Display a specific pane based on Array index in this.pane collection
Carousel.prototype.displayPane = function(paneIndex) {
  var flag = this.flags.ACTIVE;
  var active = this.panes[paneIndex];

	$.each(this.panes, function(i, pane) {
		// $(pane).hide();
    $(pane).removeClass(flag);
	});
	// $(active).show();
  $(active).addClass(this.flags.ACTIVE);
	this.updatePagination(paneIndex);
}

// Update pagination after a pane change
Carousel.prototype.updatePagination = function(paneIndex) {
	var i = index + 1;

	this.pagination.find(this.selectors.bar).removeClass(this.flags.ACTIVE);
	this.pagination.find(this.selectors.bar + ":nth-of-type(" + i + ")").addClass(this.flags.ACTIVE);
}

// Click handler for the .bar elements
Carousel.prototype.onBarClick = function(activeBar) {
	var bars = this.pagination.find(this.selectors.bar);
  var obj = this;

	$(bars).removeClass(this.flags.ACTIVE);
	$.each(bars, function(i, bar) {
		if(bar == activeBar) {
			obj.displayPane(i);
		}
	});
}

// Render the pagination control into the DOM
Carousel.prototype.renderPaginationControl = function() {
	var bar = '<span class="' + this.selectors.bar.split(".")[1] + '"></span>';
	var obj = this; // scope helper
	$(this.paneWrapper).append(
		'<div class="' + this.selectors.pagination.split(".")[1] + '">'
		+ bar.repeat(this.panes.length)
		+ '</div>'
	);
	this.pagination = $(this.element).find(this.selectors.pagination);
	$(this.pagination).find(this.selectors.bar).on("click", function(e) {
		obj.onBarClick(e.srcElement);
	});
}
