//////////////////
// Modals
//////////////////
App.Modals = (function() {

	var selectors = {
		memberLoginModal: ".modal.member-login",
		memberLoginOpen: "a[data-modal='#login']",
		modalLoginClose: "button.modal-close"
	};

	var flags = {
		ACTIVE: "active",
		NO_SCROLL: "no-scroll"
	}

	var memberLoginModal,
			memberLoginOpenButtons,
			memberLoginCloseButton;

	function autoDisplayModals() {
		for (var modalId in modalMap) {
			if(window.location.hash && window.location.hash == modalId) {
				modalMap[modalId].call();
			}
		}
	}

	function initMemberLogin() {
		try {
			memberLoginModal = $(selectors.memberLoginModal);
			memberLoginOpenButtons = $(selectors.memberLoginOpen);
			memberLoginCloseButton = memberLoginModal.find(selectors.modalLoginClose);

			if(memberLoginModal.length && memberLoginOpenButtons.length) {
				memberLoginOpenButtons.on("click", openMemberLogin);
				memberLoginCloseButton.on("click", closeMemberLogin);
			}
		} catch(e) {
			console.log("Failed initiating Member Login", e);
		}
	}

	function openMemberLogin() {
		if(memberLoginModal) {
			$("body").addClass(flags.NO_SCROLL);
			memberLoginModal.addClass(flags.ACTIVE);
			gsap.fromTo(memberLoginModal, { opacity: 0}, {opacity: 1, duration: 1 });
			return(false);
		}
	}

	function closeMemberLogin() {
		if(memberLoginModal) {
			$("body").removeClass(flags.NO_SCROLL);
			gsap.fromTo(memberLoginModal, { opacity: 1 }, {opacity: 0, duration: 1, onComplete: function() {
				memberLoginModal.removeClass(flags.ACTIVE);
			} });
			return(false);
		}
	}

	var modalMap = {
		"#login" : openMemberLogin
	}

	App.register(function() {
		App.log("Registered: App.Modals");
		initMemberLogin();
		autoDisplayModals();
	});

	return {
		openMemberLogin: openMemberLogin
	}

})();
