//////////////////
// Helper
// ------------
// Collection of things to help layout issues:
// - The full width map on Contact (which technically isn't in the header, but looks like it is)
// - Investment hero image
//////////////////
App.Helper = (function() {

	var selectors = {
		mapWrapper: 	".map-wrapper",
		investmentHero: ".block-investment-introduction .hero .hero-inner",
		investmentTitle: "h1[data-investment-status]",
		investmentLogo: ".block-investment-introduction logo"
	};
	var states = {
		active: "active"
	}

	var mapWrapper,
	investmentHero, investmentTitle, investmentLogo;

	function initMapHandler() {
		try {
			mapWrapper = $(selectors.mapWrapper);

			if(mapWrapper.length ) {

				setMapWidth();
				$(window).on("resize", setMapWidth);

			}
		} catch(e) {
			console.log("Couldn't add " + selectors.mapWrapper, e);
		}
	}

	function setMapWidth() {
		if(App.Utilities.isSmallScreen() == false) {
			var left = mapWrapper.offset().left;
			// var width = mapWrapper.width();
			var width = window.innerWidth - left;
			mapWrapper.width(width + 1);
		}
	}

	function initInvestmentHero() {
		try {
			investmentHero = $(selectors.investmentHero);
			investmentTitle = $(selectors.investmentTitle);
			investmentLogo = $(selectors.investmentLogo);

			if(investmentHero.length) {
				setHeroSize();
				$(window).on("resize", setHeroSize);
			}
		} catch(e) {
			console.log("Couldn't fix hero image", e);
		}
	}

	function setHeroSize() {
		if(App.Utilities.isSmallScreen() == false) {
			// Use the logo and H1 position to approximate how high the image should be
			var offset = $(investmentHero).offset();
			var width = window.innerWidth - offset.left;
			investmentHero.width(width);

			var tOffset = investmentTitle.offset();
			var lOffset = investmentHero.offset();
			var height = lOffset.top - tOffset.top + investmentHero.height();
			investmentHero.height(height > width ? width : height);
		}
	}

	function blockContextualMenu() {
		$("img[src*='.gif']").each(function(i, el) {
			el.addEventListener('contextmenu', function(e) {
				e.preventDefault();
			})
		});
	}

	function openDownloadsInBlankWindow() {
		$("a[href*='.doc'], a[href*='.pdf'], a[href*='.xls'], a[href*='.zip']").each(function(i, el) {
			$(el).attr("target", "_blank");
		});
	}

	function initPanelRevealClick() {
		$(".block-panel-reveal .panel").on("click", function(i, el) {
			var btn = $(this).find("a.btn-sec");
			if(btn.length) {
				var href = btn.attr("href");
				window.location.href = href;
			}
		});
	}

	function resetScrollPosition() {
		// Pull position from window name
		var pos = App.Utilities.getSavedScrollPosition();
		if(pos !== false) {
			window.scrollTo(0, pos);
			// Reset
			window.name = "";
		}
	}

	App.register(function() {
		App.log("Registered: App.Helper");
		resetScrollPosition();
		initMapHandler();
		blockContextualMenu();
		openDownloadsInBlankWindow();
	});

	return {
		fixInvestmentHero : initInvestmentHero,
		addPanelRevealClick: initPanelRevealClick
	}

})();
