//////////////////
// Slider Controller
// ------------
// Controller for .slider objects
//////////////////
App.Slider = (function() {

	var slidersCollection = [];
	var selectors = {
		slider: ".slider"
	};

	/*
	Render all sliders in the page
	*/
	function renderSliders() {
		try {
			var sliders = $(selectors.slider);
			if(sliders.length) {
				$.each(sliders, function(i, element) {
					slidersCollection.push(
						new Slider(element)
					);
				});
			}
		} catch(e) {
			console.log("Error locating " + selectors.sliders, e);
		}
	}

	App.register(function() {
		App.log("Registered: App.Slider");
		renderSliders();
	});

})();
