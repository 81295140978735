/*******
** DOMLoader
** ------------
** All standard functions for loading external assets
*******/
var DOMLoader = function(url, domSelector) {

  if(!window.DOMLoaderCache) {
    window.DOMLoaderCache = {};
  }

  this._targetUrl = url;
  this._targetDomSelector = domSelector;

  // Success handler
  this._onSuccessHandler = function(responseData) {

    // Convert to Doc and get part
    fragment = this._convertToDOM(responseData, domSelector, url);

    // Add to local cache
    if(fragment) {
      window.DOMLoaderCache[url] = fragment;

      // Pass to callback
      this._onSuccess(fragment);
    }
  }

  // Error handler
  this._onErrorHandler = function(responseCode) {
    this._onError(responseCode);
  }


  // Start loading
  this._start = function(url, domSelector) {
    if (url.length) {

      // Check cache (but only in production) - Removed cache funcionality
      //var fragment = App.Debug ? false : window.DOMLoaderCache[url];
      var fragment = false;

      if(fragment) {

        // Run with cache
        this._convertToDOM(fragment, domSelector, true);
      } else {

        // Load asset
        this._loadExternalData(url, this._onSuccessHandler.bind(this), this._onErrorHandler.bind(this));
      }
    }
  }

  // XHR loader
  this._loadExternalData  = function(url, successCallback, errorCallback) {
    var r = new XMLHttpRequest();
    r.open("GET", url, true);
    r.onreadystatechange = function () {

      // https://javascript.info/xmlhttprequest
      // r.readyState != 4 --> removed as not needed
      if (r.status != 200) {
        errorCallback(r);
        return;
      }

      // If successful
      if(r.readyState == 4) {
        App.log("Loader: Successful request, parsing");
        successCallback(r.responseText);
      }

    }.bind(this); // Bind this scope to function

    // Commit request
    r.send();
  }

  this._convertToDOM = function(HTMLdata, domSelector, isFromCache) {
    try {
      console.log(isFromCache);
      if(isFromCache && isFromCache === true) {
        // Return cache data
        App.log("Loader: Successful cache request, parsing");
        successCallback(HTMLdata);
      } else {
        // Parse the result into a document (non-DOM object)
        var parser = new DOMParser();
        if(parser) {

          // Query that new document like a usual page
          var doc = parser.parseFromString(HTMLdata, "text/html");

          // Return that element of the virtual DOM
          if(doc) {
            var result = doc.querySelector(domSelector);
            return(result);
          }
        }
      }
      return(false);
    } catch(e) {
      console.log("Failed to parse file");
    }
  }

}

//////
// Start external load process
/////
DOMLoader.prototype.load = function(onSuccess, onError) {
  this._onSuccess = onSuccess;
  this._onError = onError;

  if(this._targetUrl && this._targetDomSelector) {
    this._start(
      this._targetUrl,
      this._targetDomSelector
    );
  }
}
