//////////////////
// Styles
// ------------
// For tackling some of Redactor's limited functionality
//////////////////
App.Styles = (function() {

	/*
	Add tick list
	*/
	function addTickList() {
		try {
			// Add class to parent
			$("li.ticklist").eq(0).parent("ol,ul").addClass("ticklist");
		} catch(e) {
			console.log("Ticklist error ", e);
		}
	}

	App.register(function() {
		App.log("Registered: App.Styles");
		addTickList();
	});

})();
